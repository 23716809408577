.Help {
    position: absolute;
    right: 40px;
    top: 40px;
    padding-top: 8px;
    padding-top: 8px;
    cursor: pointer;
    border: none;
    background: transparent;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    padding: 40px;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .close {
        position: fixed;
        right: 40px;
        top: 40px;
        border: none;
        background: transparent;
        padding-top: 8px;
        cursor: pointer;
    }

    .info-text {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .contact-text {
        font-size: 16px;
        font-weight: 700;
    }

    .footer {
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -150px;
        display: flex;
        justify-content: space-evenly;
        width: 300px;
    }
};

@media only screen and (max-width: 600px) {
    .Help {
        top: 24px;
        right: 24px;
    }

    .overlay {
        .close {
            top: 24px;
            right: 24px;
        }
    }
}

@media only screen and (max-width: 321px) {
    .overlay {
        justify-content: inherit;

        .info-text:first-of-type {
            margin-top: 40px;
        }

        .contact-text,
        .info-text {
            font-size: 12px;
        }

        .footer {
            bottom: 20px;
        }
    }
}

@media only screen and (width: 375px) {
    .overlay {
        justify-content: inherit;

        .info-text:first-of-type {
            margin-top: 40px;
        }

        .contact-text,
        .info-text {
            font-size: 15px;
        }

        .footer {
            bottom: 20px;
        }
    }
}
