.App {
  text-align: center;
  height: 100%;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

p {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Overpass', sans-serif;
}

button {
  font-family: 'Overpass', sans-serif;
}