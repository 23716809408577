a {
    text-decoration: none;
}

.Logo {    
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 40px;
    left: 40px;
    flex: 1;

    .bubble {
        background-color: rgba(255,255,255,0.15); 
        border-top-left-radius: 30px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        font-weight: 700;
        color: white;
    }

    p {
        margin: 0;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: white;
        line-height: 24px;
    }

    .text {
        font-weight: 600;
        color: rgba(255,255,255,0.8);
        padding-left: 12px;
        line-height: 40px;
        font-family: 'Overpass';
    }
}

@media only screen and (max-width: 600px) {
    .Logo {
        top: 24px;
        left: 24px;
    }
}