.QuestionCard {
    padding: 30px;
    border-radius: 16px;
    height: 436px;
    width: 267px;
    display: inline-flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 20px 100px;
    flex: 4 1;
    flex: 4;
    cursor: pointer;
    position: sticky;
    grid-row: 1;
    grid-column: 1;

    .question-text {
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        flex: 8;
        line-height: 140%;
    }

    .divider {
        border-top: 1px solid rgba(0, 0, 0, 0.30);
        margin-bottom: 20px;
    }

    .level-text {
        font-size: 14px;
        text-align: left;
        font-family: 'Merriweather';
    }
}

// iphone 12 pro max
@media only screen and (width: 428px) and (min-height: 794px) {
    .QuestionCard {
        height: 408px;
        width: 320px;
    }
}

// iphone 12
@media only screen and (width: 390px) and (min-height: 732px) {
    .QuestionCard {
        height: 390px;
        width: 282px;
    }
}

// iphone X
@media only screen and (width: 375px) and (min-height: 700px) {
    .QuestionCard {
        height: 388px;
        width: 267px;
    }
}

// iphone 6/7/8
@media only screen and (width: 375px) and (min-height: 500px) and (max-height: 699px) {
    .QuestionCard {
        height: 300px;
        width: 267px;
    }
}

// iphone SE
@media only screen and (width: 320px) {
    .QuestionCard {
        height: 225px;
        width: 212px;

        .question-text {
            font-size: 18px;
        }
    }
}
