.HomePage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    transition: background-color 0.5s ease;
    
    .header {
        flex: 1;
        max-height: 120px;
    }

    .left-overlay {
        background-color: rgba(0, 0, 0, 0);
        width: 50%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
    }

    .right-overlay {
        background-color: rgba(0, 0, 0, 0);
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
    }

    .left-graphic {
        position: absolute;
        left: 0;
        top: 45%;
        bottom: 0;
        background: url('../../assets/left.png');
        background-size: cover;
        height: 550px;
        width: 400px;
        pointer-events: none;
    }

    .right-graphic {
        position: absolute;
        right: 0;
        top: 125px;
        background: url('../../assets/right.png');
        background-size: cover;
        height: 450px;
        width: 450px;
        pointer-events: none;
    }

    .cards-container {
        position: absolute;
        top: 50%;
        margin-top: -235px;
        left: 50%;
        margin-left: -160px;
    }

    p {
        margin: 0;
        max-width: 500px;
    }

    .intro {
        max-width: 800px;
        color: white;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 50px;
        line-height: 140%;
        z-index: 1;
    }

    .start {
        background: white;
        text-decoration: none;
        border-radius: 30px;
        border: none;
        padding: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 160px;
        
        &:hover {
            transform: scale(1.1);
            transition-duration: 1s;
        }

        .start-text {
            font-weight: 600;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .start-card-1 {
        height: 440px;
        width: 288px;
        position: absolute;
        bottom: -440px;
        left: 50%;
        margin-left: -165px;
        transition: transform 0.5s linear;

        &.is-hovered {
            transform: translateY(-170px);
        }
    }

    .start-card-2 {
        height: 440px;
        width: 288px;
        position: absolute;
        bottom: -500px;
        left: 50%;
        margin-left: -380px;
        transition: transform 0.5s linear;
        
        &.is-hovered {
            transform: translateY(-170px) rotate(-10deg) ;
        }
    }

    .start-card-3 {
        height: 440px;
        width: 288px;
        position: absolute;
        bottom: -500px;
        left: 50%;
        margin-left: 50px;
        transition: transform 0.5s linear;
        
        &.is-hovered {
            transform: translateY(-170px) rotate(10deg) ;
        }
    }

    .mobile-buttons {
        display: flex;
        position: absolute;
        bottom: 100px;
        margin-left: -35px;
        flex-direction: column-reverse;

        .next {
            margin-bottom: 20px;
            height: 42px;
            width: 179px;
            background-color: white;
            color: black;
            font-family: 'Overpass';
            border: none;
            border-radius: 39px;
            font-size: 16px;
            font-weight: bold;
        }

        .back {
            height: 42px;
            width: 179px;
            background: transparent;
            border: none;
            color: white;
            font-family: 'Overpass';
            font-size: 16px;
            font-weight: bold;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 410px;        
        justify-content: space-between;
        flex: 1;

        button {
            background-color: transparent;
            color: white;
            border: none;
            font-size: 24px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
        }

        .back {
            position: absolute;
            padding-right: 200px;
            padding-top: 200px;
            padding-bottom: 200px;
            padding-left: 50px;
            left: 0;
            top: calc(50% - 200px);
        }

        .next {
            position: absolute;
            padding-right: 50px;
            padding-top: 200px;
            padding-bottom: 200px;
            padding-left: 200px;
            right: 0;
            top: calc(50% - 200px);
        }
    }

    .TutorialContent {
        position: absolute;
        top: 50%;
        margin-top: -235px;
        left: 50%;
        margin-left: -160px;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 1s linear;
        
        .card {
            background-color: white;
            border-radius: 16px;
            height: 496px;
            width: 327px;
            text-align: left;
            margin-bottom: 30px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 20px 100px;

            .image {
                width: 100%;
                height: 150px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                background: url('../../assets/Instructions.png');
                background-size: cover;
                background-color: rgb(255, 194, 245);
            }

            .content {
                padding: 30px;

                .title {
                    margin-bottom: 15px;
                    font-weight: 800;
                    font-size: 18px;
                    font-family: 'Merriweather';
                }
        
                .text {
                    margin-bottom: 15px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }

        .button {
            background: white;
            color: black;
            border: none;
            padding: 12px 24px;
            border-radius: 30px;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
                transition-duration: 1s;
            }
            
            span {
                font-weight: 700;
                font-size: 16px;

            }
        }
    }

    .finished-content {
        background: #4E65E0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
            color: white;
            font-size: 26px;
            font-weight: 600;
            width: 385px;
            margin-bottom: 20px;
        }

        .body {
            color: white;
            font-size: 16px;
            width: 440px;
            margin-bottom: 40px;
            line-height: 24px;
        }

        .cta-buttons {
            display: flex;
            flex-direction: row;

            .get-physical-deck,
            .play-again {
                background: white;
                text-decoration: none;
                border-radius: 30px;
                border: none;
                padding: 12px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 160px;
                color: black;
                font-weight: 600;
                font-family: 'Overpass';
                margin-left: 15px;
                margin-right: 15px;

                &:hover {
                    transform: scale(1.1);
                    transition-duration: 1s;
                }
            }
        }

        .footer {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            width: 300px;
        }

        .success-graphic {
            position: absolute;
            bottom: 0;
            margin-bottom: -125px;
            background: url('../../assets/success.png');
            background-size: cover;
            height: 300px;
            width: 400px;
        }
    }

    @media only screen and (max-width: 600px) {
        .summaryText {
            max-width: 300px;
        }
    }
}

// all mobile devices
@media only screen and (max-width: 600px) {
    .HomePage {
        .header {
            max-height: 80px;
        }

        .intro {
            font-size: 22px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .buttons {
            width: 100%;
            
            .back {
                position: absolute;
                bottom: 20px;
                left: 24px;
                margin-bottom: auto;
                margin-left: auto;
                top: auto;
                font-size: 18px;
                padding: inherit;
            }

            .next {
                position: absolute;
                bottom: 20px;
                right: 24px;
                margin-bottom: auto;
                margin-left: auto;
                top: auto;
                font-size: 18px;
                padding: inherit;
            }
        }

        .finished-content {
            .title {
                color: white;
                font-size: 18px;
                font-weight: 600;
                width: 250px;
                margin-top: 15px;
            }
    
            .body {
                color: white;
                font-size: 14px;
                width: 260px;
                margin-bottom: 20px;
            }

            .cta-buttons {
                display: flex;
                flex-direction: column;

                .get-physical-deck {
                    margin-top: 10px;
                }
            }

            .footer {
                bottom: 20px;
            }

        }
        
        .start-card-3,
        .start-card-2,
        .start-card-1 {
            display: none;
        }

        .TutorialContent {
            .card {
                height: 400px;
                margin-bottom: 40px;

                .content {
                    .text {
                        font-size: 16px;
                    }
                }
            }
        }

        .stacked-cards {
            height: 600px;
            width: 476px;
            position: fixed;
            top: 100%;
            margin-top: -200px;
            pointer-events: none;
        }
    }
}

// iphone 12
@media only screen and (width: 390px) and (min-height: 732px) {
    .HomePage {
        .TutorialContent {
            margin-left: -165px;
            margin-top: -250px;

            .card {
                height: 450px;
            }
        }

        .mobile-buttons {
            bottom: 30px;
            margin-left: -85px;
        }

        .cards-container {
            margin-top: -250px;
            margin-left: -172px;
        }

        .mobile-buttons {
            bottom: 20px;
        }
    }
}

// iphone 12 pro max
@media only screen and (width: 428px) and (min-height: 814px) {
    .HomePage {
        .cards-container {
            margin-left: -190px;
            margin-top: -225px;
        }

        .TutorialContent {
            margin-left: -185px;

            .card {
                height: 468px;
                width: 370px;

                .content {
                    .text {
                        font-size: 15px;
                    }
                }
            }
        }

        .mobile-buttons {
            bottom: 30px;
            margin-left: -90px;
        }
    }
}

// iphone X
@media only screen and (width: 375px) and (min-height: 700px) {
    .HomePage {
        
        .TutorialContent {
            position: absolute;
            margin-top: -245px;
            margin-left: -165px;
            top: 50%;
            
            .card {
                height: 448px;
                width: 327px;
                
                .content {
                    .text {
                        font-size: 15px;
                    }
                }
            }
        }
        
        .cards-container {
            margin-left: -165px;
            margin-top: -245px;
        }

        .mobile-buttons {
            bottom: 20px;
            margin-left: -90px;
        }
    }
}

// iphone SE 2020 / 6 / 7 / 8
@media only screen and (width: 375px) and (min-height: 500px) and (max-height: 699px) {
    .HomePage {
        .cards-container {
            margin-left: -165px;
            margin-top: -195px;
        }

        .TutorialContent {
            position: absolute;
            top: 50%;
            margin-top: -195px;
            left: inherit;
            margin-left: inherit;

            .card {
                height: 360px;
                width: 337px;
                margin-bottom: 25px;
               
                .image {
                    height: 100px;
                }

                .content {
                    .text {
                        font-size: 13px;
                    }
                }
            }
        }

        .stacked-cards {
            margin-top: -180px;
            height: 450px;
            width: 667px;
        }

        .mobile-buttons {
            margin-left: -80px;
            bottom: 10px;

            .next {
                margin-bottom: 10px;
                height: 35px;
                width: 155px;
            }

            .back {
                height: 35px;
                width: 155px;
            }
        }

        .finished-content {
            .title {
                margin-top: 45px;
            }
        }
    }
}

// smallest desktop window width
@media only screen and (width: 500px) {
    .HomePage {
        .cards-container {
            margin-left: -208px;
        }
    }
}

// iphone 5/ SE
@media only screen and (width: 320px) {
    .HomePage {
        .cards-container {
            margin-top: -155px;
            margin-left: -137px;
        }

        .TutorialContent {
            margin-top: -200px;         
        }

        .mobile-buttons {
            bottom: 0;
            margin-left: -85px;
            
            .next {
                margin-bottom: 0;
            }
        }
    }
}

// iphone SE
@media only screen and (width: 320px) and (min-height: 568px) {
    .HomePage {
        .cards-container {
            position: inherit;
            top: inherit;
            left: inherit;
            margin-left: inherit;
            margin-top: -90px;
        }

        .intro {
            font-size: 22px;
        }

        .TutorialContent {
            position: absolute;
            top: 50%;
            margin-top: -205px;
            left: inherit;
            margin-left: inherit;
            margin-left: 24px;
            margin-right: 24px;

            .card {
                height: 408px;
                width: 272px;
                margin-bottom: 15px;

                .content {
                    .text {
                        font-size: 12px;
                    }
                }
            }
        }

        .buttons {
            .back,
            .next {
                bottom: 15px;
            }
        }

        .stacked-cards {
            height: 600px;
            width: 476px;
            position: fixed;
            top: 100%;
            margin-top: -175px;
            pointer-events: none;
        }

        .mobile-buttons {
            bottom: 3px;
            margin-left: 55px;
            
            .next {
                margin-bottom: 0;
                height: 35px;
                width: 155px;
            }

            .back {
                height: 35px;
                width: 155px;
            }
        }
    }
}

.fadeIn {
    animation: fadeInAnimation 2s;
}

.fadeOut {
    animation: fadeOutAnimation 2s;
}

.slideLeft {
    animation: slideLeftAnimation 2s;
}

.expandCard {
    animation: expandCardAnimation 1s;
}

.darken {
    animation: darkenAnimation 0.5s;
    animation-fill-mode: forwards;
}

@keyframes darkenAnimation {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@keyframes expandCardAnimation {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeInAnimation {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeOutAnimation {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes slideLeftAnimation {
    from {
        opacity: 1;
    }
    
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideRightAnimation {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
}
