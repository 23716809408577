$width: 80;
$color: white;

.LoadingIcon {    
    .ball-loader{
      width: $width + 0px;
      height: ($width / 3) - 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-58%);
      margin-bottom: 10px;
    }
    .ball-loader-ball{
      will-change: transform;
      height:($width / 3) - 10px;
      width: ($width / 3) - 10px;
      border-radius: 50%;
      background-color: $color;
      position: absolute;
      animation: grow 0.8s ease-in-out infinite alternate;
      
      
      &.ball1{
        left:0;
        transform-origin: 100% 50%;
      }
      &.ball2{
        left:50%;
        transform: translateX(-50%) scale(1);
        animation-delay: 0.33s;
      }
      &.ball3{
        right:0;
        animation-delay: 0.66s;
      }
    }    
}

@keyframes grow{
    to{
    transform: translateX(-50%) scale(0);
    }
}